.heroContainer {
  width: 100%;
  height: 100vh;
  /* background: var(--primary-color); */
  justify-content: space-around;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.heroContainer2 {
  width: 100%;
  height: 100vh;
  background: rgba(15, 15, 104, 0.7);
  /* background: var(--primary-color); */
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.heroVideo {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 500px) {
  .heroContainer {
    height: 85vh;
    /* padding-bottom: 20px; */
  }
  .heroVideo {
    height: 85vh;
  }
  .heroContainer2 {
    height: 85vh;
    /* padding-bottom: 20px; */
  }
}

.heroHeading {
  color: var(--white-text-color);
}

@media only screen and (max-width: 980px) {
  .heroImage {
    padding-top: 100px;
  }
}
