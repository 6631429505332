.cardContainer {
  background-color: var(--shades-light-color);
  min-height: 515px;
  /* padding: 20px 0px; */
}

.cardParagraph {
  min-height: 140px;
  text-align: "center";
  margin: 0px 20px;
  color: var(--secondary-text-light);
}
