.infoContainer {
  padding: 20px 0px;
}

.footerLinks {
  font-size: smaller;
  line-height: normal;
  color: var(--light-text-color);
  text-decoration: none;
  padding: 1px 0px;
}
.mainHeaderContainer {
  background-color: white;
  /* padding: 50px 0px 50px 0px; */
}

.mainLogoContainer {
  background-color: white;
  z-index: 10;
  /* padding: 70px 0px 50px 0px; */
}

.headerLogo {
  position: relative;
  top: 40px;
  left: 140px;
  width: 60%;
  /* width: 430px; */
}
@media only screen and (max-width: 990px) {
  .headerLogo {
    left: 40px;
  }
  .mainHeaderContainer {
    height: 150px;
  }
}

@media only screen and (max-width: 780px) {
  .headerLogo {
    width: 100%;
    position: relative;
    top: 50px;
    left: 25px;
  }
}

@media only screen and (max-width: 550px) {
  .headerLogo {
    width: 150%;
    position: relative;
    top: 50px;
    left: 25px;
  }
  .mainHeaderContainer {
    height: 110px;
  }
}
