.infoContainer {
  padding: 20px 0px;
}
.footerLinks {
  font-size: smaller;
  line-height: normal;
  color: var(--light-text-color);
  text-decoration: none;
  padding: 1px 0px;
}

.footerLinks:hover {
  color: rgb(151, 150, 150);
}

.footerLinks3 {
  font-size: smaller;
  line-height: normal;
  color: var(--light-text-color);
  text-decoration: none;
  padding: 1px 0px;
}

.footerLinks2 {
  font-size: xx-small;
  line-height: normal;
  color: var(--light-text-color);
  text-decoration: none;
  padding: 1px 0px;
}

.footerSocials {
  font-size: medium;
  line-height: normal;
  color: var(--light-text-color);
  text-decoration: none;
  padding: 1px 0px;
}

.footerSocialIcons {
  text-align: start;
  padding: 0px;
}

.mainContainer {
  background-color: var(--primary-color);
  padding: 0px 0px 0px 30px;
}
