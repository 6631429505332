.main-container {
  background-color: var(--shades-light-color);
  /* padding: 50px 0px; */
}

h4 {
  color: var(--primary-text-color);
}

.form-inputs {
  border: 0.4px var(--shades-light-color) solid;
  padding: 20px;
  margin: 5px 0px;
}

.submitButton {
  color: var(--white-text-color);
  background-color: var(--primary-text-color);
  border: 0px;
  margin-top: 5px;
  padding: 15px 40px;
  border-radius: 25px;
  font-size: 22px;
  font-weight: bolder;
}

@media only screen and (max-width: 600px) {
  .submitButton {
    /* margin-left: 30px; */
  }
}

.contactContainer {
  padding: 100px 0px 50px 0px;
}

.contactForm {
  z-index: 2;
}

.tel {
  color: black;
  text-decoration: none;
}

.tel:hover {
  color: grey;
}

.tel2 {
  color: var(--light-text-color);
  text-decoration: none;
}

.tel2:hover {
  color: grey;
}
