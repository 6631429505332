.infoContainer {
  padding: 20px 0px;
}

.footerLinks {
  font-size: smaller;
  line-height: normal;
  color: var(--light-text-color);
  text-decoration: none;
  padding: 1px 0px;
}
.mainSidebarContainer {
  padding: 50px 0px 50px 0px;
  position: absolute;
  right: 0px;
  top: 20px;
  /* height: 100%; */
}

.mainLogoContainer {
  z-index: 99;
  /* padding: 70px 0px 50px 0px; */
}

.headerLogo {
  position: relative;
  top: 40px;
  left: 140px;
  width: 430px;
}

@keyframes right-animate {
  0% {
    transform: translateX(500px);
  }
  100% {
    transform: translateX(0px);
  }
}

.fqButtonOpen {
  z-index: 21;
  color: white;
}

.ffq {
  animation: right-animate 1s ease alternate;
}

.fqButton {
  z-index: 21;
  color: var(--primary-text-color);
  transition: 0.4s ease-in;
}

.hamButton {
  z-index: 21;
}

@media only screen and (max-width: 550px) {
  .fqButton {
    display: none;
  }
  .mainSidebarContainer {
    top: -10px;
  }
}

.sidebarOpen {
  position: fixed;
  height: 100vh;
  width: 50%;
  background-color: rgba(1, 1, 77, 0.9);
  z-index: 20;
  /* display: none; */
  right: 0px;
  top: 0px;
  padding: 0px;
  /* transition: all 3.5s ease-out; */
  transition: 0.4s ease-in;
}

.sidebar-closed {
  /* min-width: 40px; */
  width: 0px;
  display: none;
  /* background-color: red; */
}

.sidebarLinks {
  text-decoration: none;
  color: var(--white-text-color);
  font-size: xx-large;
  padding: 5px;
}

.sidebarLinks:hover {
  color: rgb(151, 150, 150);
}

@media only screen and (max-width: 600px) {
  .sidebarOpen {
    width: 100%;
  }
  .sidebar-closed {
    /* min-width: 40px; */
    width: 0px;
    display: none;
    /* background-color: red; */
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}
