body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primary-color: #2e3192;
  --primary-text-color: #2e3192;
  --secondary-text-color: #606061;
  --tertiary-text-color: #ffd127;
  --white-text-color: #ffffff;
  --light-text-color: #d7d7d7;
  --shades-white-color: #ffffff;
  --shades-light-color: #f2f2f2;

  font-size: large;
}

.row {
  margin: 0px;
  padding: 0px;
}

.h1g {
  font-weight: 700;
  /* font-family: "Helvetica 65 Medium"; */
}

.para {
  font-size: large;
}

/* 
ul {
  list-style: none;
}

li::before {
  content: "•";
  color: red
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
