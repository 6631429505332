.headin {
  color: var(--primary-text-color);
  align-self: center;
  text-align: center;
  padding: 40px 0px 10px 0px;
  align-items: center;
  justify-self: center;
  justify-content: center;
}

.marking {
  height: 10px;
  width: 80px;
  background-color: var(--tertiary-text-color);
  display: inline-block;
  margin: 20px 0px 0px 0px;
}
