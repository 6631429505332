.infoContainer {
  padding: 20px 0px;
}

.footerLinks {
  font-size: smaller;
  line-height: normal;
  color: var(--light-text-color);
  text-decoration: none;
  padding: 1px 0px;
}

.mainBannerContainer {
  padding: 0px;
  margin: 0px;
  /* background-color: var(--light-text-color); */
  border-top: 8px solid var(--primary-color);
  /* height: 250px; */
  /* padding: 400px 0px 0px 0px; */
}
